<template>
    <div class="multi-select">
        <CheckboxField v-for="(option, index) in options" v-bind:key="option.value" v-bind:data="option"
            :value="option.value" :label="option.label" :checked="option.selected"
            @input="updateSelection(option, $event)" :class="['field', { 'field-full': fullWidth }]" />
    </div>
</template>

<script>
import CheckboxField from './CheckboxField'

export default {
    name: 'MultiSelect',

    components: {
        CheckboxField
    },

    data() {
        return {
            selected: [],
        }
    },

    props: {
        options: {
            type: Array,
            required: true
        },
        fullWidth: {
            type: Boolean,
            default: false
        }
    },

    created() {
        this.options.forEach(option => {
            if (option.selected) {
                this.selected.push(option.value)
            }
        })
    },

    methods: {
        updateSelection(option, checked) {
            if (checked) {
                if (!this.selected.includes(option.value)) {
                    this.selected.push(option.value);
                }
            } else {
                const index = this.selected.indexOf(option.value);
                if (index !== -1) {
                    this.selected.splice(index, 1);
                }
            }

            this.$emit('change', this.selected)
        },
    },


}
</script>



<style lang="scss" scoped>
.multi-select {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;

    &.full-width {
        flex-direction: column; // Optional: Adjust flex settings for full width layout
    }

    .field {
        width: 50%;
        margin-bottom: 10px;

        @include breakpoint('mobile') {
            width: 100%;
        }
    }

    .field-full {
        width: 100%;
    }



}
</style>
